import _helperPluginUtils from "@babel/helper-plugin-utils";
var exports = {};
const {
  declare
} = _helperPluginUtils;

class BabelPluginExtractImportNames {
  constructor() {
    const names = [];
    this.state = {
      names
    };
    this.plugin = declare(api => {
      api.assertVersion(7);
      return {
        visitor: {
          ImportDeclaration(path) {
            path.traverse({
              Identifier(path) {
                if (path.key === "local") {
                  names.push(path.node.name);
                }
              }

            });
          }

        }
      };
    });
  }

}

exports = BabelPluginExtractImportNames;
export default exports;